.radioContainer {
  display: inline-block;
  position: relative;
  height: 20px;
  width: 20px;
  margin-bottom: 0;
  margin-right: 10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 20px;
  background-color: transparent;
  border: 2px solid #0f0f0f;
  border-radius: 50%;
}

.radioContainer:hover input ~ .checkmark:after {
  opacity: 0.8;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.isChecked:after {
  display: block;
}

.radioContainer .checkmark:after {
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: black;
}
