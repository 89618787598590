.disclosures {
  display: flex;
  margin-top: 20px;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 25px;
  color: rgba(0, 0, 0, 0.5);
}

.disclosures a {
  color: rgba(0, 0, 0, 0.5);
}

:global(.dark) .disclosures {
  color: rgba(255, 255, 255, 0.5);
}

:global(.dark) .disclosures a {
  color: rgba(255, 255, 255, 0.5);
}

:global(.dark) .disclosures a:hover {
  color: var(--candor-neon-green);
}

.disclosuresSidebar {
  flex: 0 0 30%;
  padding-right: 40px;
}

.disclosuresSidebar a {
  display: inline-block;
  margin-top: 5px;
}

.disclosuresSidebarCopyright {
  margin-top: 20px;
}

.disclosuresBody {
  flex: 0 70%;
}

.disclosuresBody a {
  text-decoration: underline !important;
}

.disclosuresBodyEmphasis {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.disclosuresBody p {
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 18px;
}

/* Mobile */
@media (max-width: 600px) {
  .disclosures {
    display: block;
  }

  .disclosuresSidebar {
    margin-bottom: 30px;
    padding-right: 0;
  }
}
