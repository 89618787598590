.form {
  display: flex;
  justify-content: space-between;
}

.form input {
  height: 40px;
  line-height: 40px;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}

.form input[type="email"] {
  flex: 0 0 65%;
  width: 65%;
}

.form :global .decoy {
  position: absolute;
  left: -5000px;
}

.form input[type="submit"] {
  flex: 0 0 30%;
  width: 30%;
  padding: 0;
  font-weight: bold;
  box-shadow: none;
}

.form .formResponse {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}

.form :global .success {
  margin-top: 12px;
  color: #52c41a;
}

.form :global .success::before {
  content: "✔ Got it!";
  font-weight: 700;
  padding-right: 4px;
}

.form :global .error {
  margin-top: 12px;
  color: #f5222d;
}

.form :global .error::before {
  content: "Error.";
  font-weight: 700;
  padding-right: 4px;
}
