.drawer {
  display: flex;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #cfd3d6;
  cursor: pointer;
}

.drawer:hover {
  background-color: #f7f8f9;
}

.icon {
  width: 10px;
  height: 10px;
}

.iconWrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #c6cacd;
  margin-right: 20px;
}

.titleHolder {
  align-self: center;
  flex: 1;
}

.iconReversed {
  transform: rotate(180deg);
}

.iconWrapper:hover {
  cursor: pointer;
}

.drawerContentHolder {
  border-radius: 8px;
  border: 1px solid #c6cacd;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 24px;
}

.drawerExpanded {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media (max-width: 800px) {
  .drawer {
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}
