.legendEntryWrapper {
  display: flex;
  min-width: max-content;
}

.legendTitle {
  color: #0f0f0f;
  opacity: 0.5;
}

.legendValue {
  color: #0f0f0f;
}

.legendMarker {
  margin-right: 15px;
  margin-top: 3px;
  height: 16px;
  width: 16px;
  border-radius: 2px;
}

@media (max-width: 600px) {
  .legendEntryWrapper {
    min-width: initial;
    max-width: 70%;
    min-width: 40%;
  }
}
