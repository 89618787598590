.radioContainer {
  display: flex;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radioContainerDisabled {
  cursor: not-allowed;
}

.radioContainer input {
  position: relative;
  opacity: 0;
  margin: 0;
}

.checkMark {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  aspect-ratio: 1;
  min-width: 20px;
  min-height: 20px;
  background-color: transparent;
  border: 2px solid #0f0f0f;
  border-radius: 50%;
}

.radioContainer:hover input ~ .checkMark:after {
  opacity: 0.8;
}

.checkMark:after {
  content: "";
  /* position: relative; */
  display: none;
}

.radioContainer input:checked ~ .checkMark:after {
  display: block;
}

.radioContainer input:disabled ~ .checkMark {
  background: var(--Grey-1, #e6e8ea);
  border: 2px solid #6b7075;
}

.radioContainer input:disabled ~ .title {
  color: var(--Grey-5, #6b7075);
}

.radioContainer .checkMark:after {
  /* fallback */
  width: 12px;
  height: 12px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 50%;
  background: black;
}

.title {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  left: 20px;
}
