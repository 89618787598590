.container {
  display: flex;
  min-width: min-content;
}

.separator {
  border-bottom: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  margin: 0px;
  height: auto;
}

.wrapChildren > * {
  flex: 1 1 var(--custom-basis) !important;
}

/* Mobile */
@media (max-width: 600px) {
  .container {
    min-width: initial;
  }
}
