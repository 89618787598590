.header {
  padding: 20px 0px;
}

.header :global(.widecontainer) {
  display: flex;
  align-items: center;
}

.black {
  color: white;
  background: var(--Primary-Grey-9, #141719);
}

.black a:hover {
  color: white;
}

.white {
  background-color: white;
}

.headerLogo {
  /* nudge for visual alignement */
  margin-bottom: 2px;
}

.black .logo path {
  fill: white;
}

.logo:hover path {
  fill: grey;
}

.black .logo:hover path {
  fill: var(--candor-neon-green);
}

.logo > svg {
  height: 28px;
}

.mobileNavMenu {
  display: none;
}

.headerContent {
  display: flex;
  flex: 1 1 auto;
  margin-left: 48px;
}

.headerContent > nav {
  flex: 1 1 auto;
}

.headerContent *::before {
  content: none;
}

.headerContent nav a {
  text-decoration: none;
  user-select: none;
}

.headerContent > nav > *,
.menuCTALink {
  cursor: pointer;
  padding: 0 15px;
  border-radius: 20px;
  transition: all 200ms ease-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
}

.black .headerContent > nav > *:hover,
.black .menuCTALink:hover {
  background-color: rgb(255, 255, 255, 0.25) !important;
  box-shadow: none !important;
}

.white .headerContent > nav > *:hover,
.white .menuCTALink:hover {
  background: var(--Grey-0, #f8f9f9);
  color: #141719;
}

.menuCTA {
  flex: 0 0 auto;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.menuCTA :global .link {
  border: none;
}

.loggedIn .menuCTAButton {
  margin-right: 12px;
}

.navMenu a {
  color: black;
}

.black .navMenu a {
  color: white;
}

.navMenuItemSubmenu {
  background: white;
  display: none;
  font-weight: 500;
  font-size: 16px;
  color: black;
  border-radius: 10px;
  width: 318px;
  cursor: pointer;
}

.navMenuItemSubmenu a,
.black .navMenuItemSubmenu a {
  color: black;
}

.navMenuItemSubmenu:after {
  content: "";
  width: 25px;
  height: 25px;
  background-color: white;
  z-index: -1;
  position: absolute;
  top: -5px;
  left: calc(50% - 12px);
  transform: rotate(45deg);
}

.navMenuItemSubmenu a {
  padding: 25px 22px;
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: white;
  position: relative;
  text-decoration: none;
}

.navMenuItemSubmenu.condensed a {
  display: block;
}

.navMenuItemSubmenu a::before {
  content: "";
  border-bottom: 1px solid #e6e6e6;
  position: absolute;
  bottom: 0px;
  left: 20px;
  right: 20px;
  z-index: 1;
}

.navMenuItemSubmenu a:hover::before,
.navMenuItemSubmenu a.activePage::before,
.navMenuItemSubmenu a.hideSplit::before {
  border-bottom-color: white;
  left: 0;
  right: 0;
}

.navMenuItemSubmenu.condensed a:last-of-type::before {
  content: "";
}

.navMenuItemSubmenu a:first-of-type {
  border-radius: 10px 10px 0px 0px;
}

.navMenuItemSubmenu a:last-of-type {
  border-radius: 0px 0px 10px 10px;
}

.navMenuItemSubmenu a.hideSplit {
  border-radius: 0px;
}

.navMenuItemSubmenu a:hover {
  background: var(--candor-background-grey);
  color: var(--candor-black);
}

.navMenuItemSubmenu a:hover span:not(.submenuTitleArrow) {
  text-decoration: underline;
}

.navMenuItemSubmenu a:first-of-type:hover,
.navMenuItemSubmenu a:first-of-type {
  border-top: 0px;
}

.black .navItemActive {
  display: inline-flex;
  padding: 5px 14px;
  align-items: center;
  border-radius: 30px;
  background: var(--Grey-7, #41464c);
}

.white .navItemActive {
  display: inline-flex;
  padding: 5px 14px;
  align-items: center;
  border-radius: 30px;
  background: var(--Grey-1, #e6e8ea);
}

.active .navMenuItemSubmenu:before {
  /* To not flick off submenu when moving cursor from menu to submenu */
  content: "";
  height: 50px;
  width: 100%;
  position: absolute;
  top: -40px;
}

.active .navMenuItemSubmenu {
  display: block;
  position: absolute;
  top: 60px;
  box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.25);
  z-index: 100;
  left: 380px;
}

.submenuItemIconContainer {
  display: flex;
  align-items: center;
}

.submenuIcon {
  height: 44px;
  width: 38px;
  min-width: 38px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #73736e;
  display: flex;
  justify-content: center;
}

.moreSubmenus {
  display: flex;
  align-items: flex-start;
  background: #fff4e5;
  padding: 25px;
  cursor: auto;
  border-radius: 0px 0px 10px 10px;
  gap: 14px;
}

.moreSubmenus .submenuIcon {
  padding-top: 2px;
}

.moreSubmenusLinks {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.moreSubmenus a {
  background: #fff4e5;
  border-radius: 0px 0px 10px 10px;
  padding: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--candor-black);
}

.moreSubmenus a:hover {
  background: #fff4e5;
  color: #a07850;
}

.moreSubmenus a::before {
  content: none;
}

.demoButton {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

a.activePage {
  background: var(--candor-background-grey);
}

.moreSubmenusLinks a.activePage {
  background: #fff4e5;
  color: #a07850;
}

.submenuTitleArrow {
  display: none;
}

a:hover .submenuTitle {
  text-decoration: underline;
}

a:hover .submenuTitle::after,
.navMenuItemSubmenu.condensed a:hover::after {
  content: "→";
  display: inline-block;
  margin-left: 6px;
  text-decoration: none !important;
}

.submenuSubtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #73736e;
  margin-top: 8px;
}

.newPill {
  text-transform: uppercase;
  position: relative;
  top: -1px;
  display: inline-block;
  background: var(--candor-neon-green);
  border-radius: 2px;
  padding: 2px 6px;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: var(--candor-black);
  margin-left: 6px;
}

a:hover .newPill {
  text-decoration: none !important;
}

.newPill + .submenuTitleArrow {
  margin-left: 0px;
}

.newIndicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: var(--candor-neon-green);
  margin-right: 3px;
  margin-bottom: 1px;
}

.caret {
  height: 10px;
  margin-left: 6px;
  transform: rotate(-90deg);
  fill: var(--candor-grey-70);
}

.banner {
  background-color: var(--candor-neon-green);
  width: 100%;
  padding: 10px 0;
  text-align: center;
}

.banner a {
  color: black;
  margin-left: 2px;
}

.banner a:hover {
  color: rgb(0, 0, 0, 0.5);
}

.desktopNavContent {
  display: flex;
  align-items: center;
  gap: 12px;
}

.navMenuTitleContainer {
  display: inline-flex;
  align-items: center;
  padding: 5px 14px;
  border-radius: 30px;
  min-height: 36px;
}

.navMenuTitleContainer:hover {
  background: #141719;
  color: #fff;
}

.navMenuTitleContainer:hover .navMenuTitle {
  background: #0f0f0f;
  color: #fff;
}

.menuCTAButton {
  cursor: pointer;
  padding: 0 15px;
  border-radius: 20px;
  transition: all 200ms ease-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
}

.menuCTAButton {
  padding: 0 25px;
  border: none;
  color: black;
  background: var(--candor-neon-green);
  font-weight: 500;
  margin-left: 8px;
  border-radius: 20px;
  min-height: 36px;
  text-decoration: none;
}

.menuCTAButton:hover {
  background: var(--candor-light-green);
  color: black !important;
}

.loggedIn .menuCTAButton {
  margin-right: 12px;
}

/* Mobile */
@media (max-width: 600px) {
  .header {
    width: 100%;
    z-index: 100;
  }

  .headerExpanded {
    padding-bottom: 0px;
    position: fixed;
    height: 100vh;
  }

  .logo {
    margin-top: 3px;
  }

  .logo svg {
    height: 24px;
  }

  .header :global(.widecontainer) {
    display: block;
  }

  .headerContent {
    display: block;
    width: 100vw;
    position: relative;
    left: -20px;
    overflow: hidden;
    max-height: 0;
    transition:
      max-height 0.5s,
      margin-top 0.5s;
    background-color: #f4f4f4;
    color: black;
    border-bottom: none;
    border-radius: 4px;
    padding: 0;
    margin-left: 0px;
    margin-top: 0;
    border-radius: 0px;
  }

  .headerContent {
    background-color: white;
  }

  .headerContent.expanded {
    max-height: 1000px;
    overflow-y: auto;
    margin-top: 20px;
  }

  .black .headerContent {
    color: white;
    background: var(--Primary-Grey-9, #141719);
  }

  .desktopNavContent {
    display: none;
  }

  .white .headerContent > nav > * {
    background: white !important;
    color: var(--candor-black) !important;
  }

  .headerLogo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  }

  .mobileNavMenu {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
  }

  .mobileNavMenuButton {
    display: inline-block;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-right: 15px;
    width: 24px;
    height: 24px;
  }

  .black .mobileNavMenuButton {
    border-color: white;
  }

  .white .mobileNavMenuButton > * {
    stroke: black;
    fill: black;
  }

  .black .mobileNavMenuButton > *,
  .black .mobileNavMenuButton {
    fill: white;
  }

  .headerContent > nav > * {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: padding 0ms;
    padding: 20px 0px;
    margin: 0px 20px;
    border-radius: 0px;
    position: relative;
    min-height: auto;
  }

  .headerContent > nav > .active {
    display: block;
  }

  .headerContent > nav > *:after {
    content: "";
    display: block;
    border-bottom: 1px solid #e6e6e6;
    position: absolute;
    width: 100%;
    bottom: -0px;
  }

  .black .navMenu a {
    color: black;
  }

  .navMenu > span {
    position: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .headerContent > .navMenu > .active:hover {
    background-color: var(--candor-background-grey) !important;
    padding-bottom: 0px;
    margin: 0px;
    padding: 20px 20px 0px;
  }

  .headerContent > nav > *:hover {
    box-shadow: none;
  }

  .navMenuTitle {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
    width: 100%;
    justify-content: flex-end;
    position: relative;
    padding-left: 20px;
  }

  .caret {
    position: absolute;
    display: flex;
    width: 12px;
    right: 0px;
  }

  .caret > * {
    fill: #5a5a55;
  }

  .active .caret {
    transform: rotate(180deg);
  }

  .closeIcon {
    width: 16px;
    height: 16px;
  }

  .headerContent > nav > * > .navMenuTitle::after {
    float: right;
  }

  .headerContent > nav > .active > .navMenuTitle::after {
    transform: rotate(180deg);
  }

  .headerContent > nav > .active .navMenuItemSubmenu {
    position: static;
    box-shadow: none;
    width: 100vw;
    margin-left: -20px;
    margin-top: 20px;
  }

  .navMenuItemSubmenu:after {
    content: none;
  }

  .moreSubmenusLinks a {
    background-color: #fff4e5;
  }

  .active .navMenuItemSubmenu:before {
    content: none;
  }

  .moreSubmenus {
    margin: 18px 20px;
    border-radius: 5px;
  }

  .navMenuItemSubmenu.condensed a {
    padding-left: 40px;
  }

  .navMenuItemSubmenu a:first-of-type,
  .navMenuItemSubmenu a:last-of-type {
    border-radius: 0px;
  }

  .menuCTALink {
    color: #101010;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }

  .black .headerContent > nav > *:hover,
  .black .menuCTALink:hover {
    background-color: none;
    color: black !important;
  }

  .menuCTA {
    padding: 15px 20px;
    margin-top: 64px;
    text-align: center;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: space-between;
  }

  .menuCTA.loggedIn :global(.cl-user-button) {
    display: none;
  }

  .newIndicator {
    margin-bottom: -1px;
  }

  .globalTopHeader {
    display: none;
  }

  .globalTopHeader a {
    font-size: 12px;
    margin-right: 24px;
  }

  .globalTopHeader a:last-child {
    margin-right: 0 !important;
  }

  .navMenu {
    margin-bottom: 36px;
  }

  .navMenuTopTitle {
    padding: 24px 20px 0;
    color: #0f0f0f;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    margin-top: 4px;
    border-top: 1px solid #e5e7e8;
  }

  .navTopLevelMenuTitle {
    color: #6b7075;
    line-height: 140%;
    margin: 24px 0;
    padding: 0 20px;
  }

  .navTopLevelMenu {
    margin: 24px;
  }

  .navtopLevelMenu a {
    display: block;
    color: #0f0f0f;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 24px;
    padding: 0 20px;
    text-decoration: none;
  }

  .black .navtopLevelMenu {
    border-top: 1px solid var(--Grey-7, #41464c);
    padding: 24px;
  }

  .white .navtopLevelMenu {
    border-top: 1px solid var(--Grey-Scale-Grey-30, #e5e7e8);
    padding: 24px;
  }

  .black .navtopLevelMenu a,
  .black .navMenuTitle,
  .white .navtopLevelMenu a,
  .white .navMenuTitle {
    display: flex;
    color: white;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 24px;
    text-decoration: none;
    padding: 0;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--Grey-7, #41464c);
  }

  .white .navtopLevelMenu a,
  .white .navMenuTitle {
    color: #0f0f0f;
    border-bottom: 1px solid var(--Grey-Scale-Grey-30, #e5e7e8);
  }

  .active .navMenuItemSubmenu {
    display: block;
    position: static;
    box-shadow: none;
    width: 100vw;
    margin-top: 20px;
    color: black;
  }

  .black .menuCTALink {
    color: white;
  }

  .white .menuCTALink {
    color: #0f0f0f;
  }

  .menuCTA {
    flex-direction: column-reverse;
    padding: 0 24px;
    margin-top: 0;
  }

  .black .navMenuItemSubmenu,
  .black .navMenuItemSubmenu a {
    background: var(--Primary-Grey-9, #141719);
    color: white;
    padding-left: 16px;
    font-weight: 500;
    padding-right: 24px;
    margin-right: 24px;
  }

  .white .navMenuItemSubmenu,
  .white .navMenuItemSubmenu a {
    padding-right: 24px;
    margin-right: 24px;
    padding-left: 16px;
  }

  .demoButton,
  .menuCTAButton {
    width: 100% !important;
    margin: 0;
  }

  .navMenuItemSubmenu a {
    padding: 12px;
  }

  .navMenuItemSubmenu a::before {
    display: none;
  }
}
