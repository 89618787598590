.button {
  font-size: 14px;
  font-weight: 400;
  min-height: 50px;
  border-radius: 40px;
  min-width: 180px;
  padding: 0 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  white-space: pre-wrap;
  background-color: #ffffff;
  color: var(--candor-black);
  border: 1px solid #0f0f0f;
  min-height: 60px;
}

.rectangular {
  box-sizing: border-box;
  width: 100% !important;
  margin: 0 0 10px 0;
  border-radius: 5px;
  border-color: #cfd3d6;
  background: #fff;
}

.rectangular:hover {
  border: 1.5px solid #a6c917;
  background-color: white;
}

.rectangular.active {
  border: 1.5px solid #a6c917;
  background: #f5fdd6;
  color: black;
}

.disabled,
.disabled:hover {
  background-color: var(--candor-grey-30);
  border: 1px solid var(--candor-grey-50);
  color: var(--candor-grey-60);
  cursor: not-allowed;
  box-shadow: none;
}

.loadingContainer {
  height: 20px;
  width: 20px;
  margin-left: 6px;
}

.loader,
.loader:after {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.loader {
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.8em solid #fff;
  border-right: 0.8em solid #fff;
  border-bottom: 0.8em solid #fff;
  border-left: 0.8em solid transparent;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .content {
    text-align: left;
  }
}
