@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 1s linear infinite; /* Adjust speed and easing as needed */
  transform-origin: center; /* Rotate around the center */
  display: block; /* Optional: Ensures proper rendering as a block element */
}
