.container {
  font-size: 12px;
  line-height: 15px;
  color: #808080;
  position: relative;
}

.container.desktopEnabled {
  height: 30px;
  overflow: hidden;
}

.container.expanded {
  height: auto;
}

.container .expandLink {
  /* by default hide link */
  display: none;

  background: inherit;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-left: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.container.desktopEnabled .expandLink {
  display: block;
}

.showDisclaimer:hover {
  color: rgb(200, 160, 110);
}

@media (max-width: 600px) {
  .container {
    height: 30px;
    overflow: hidden;
  }

  .container .expandLink {
    display: block;
  }
}
