.input,
.select {
  height: 48px;
  font-size: 16px;
  margin: 4px 2px;
  text-align: left;
  padding: 12px 18px;
  border: 1px solid #cfd3d6;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 2px;
  color: #000000;
}

.password {
  position: relative;
  display: flex;
  align-items: center;
}

.password > svg {
  width: 16px;
  height: 12px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.password > svg:hover > path {
  fill: #000000;
}

.input:focus,
.select:focus {
  border-width: 2px;
  outline: 0;
  border: 1px solid #caa26f;
  box-shadow: 0px 0px 0px 2px rgba(202, 162, 111, 0.2);
  border-radius: 5px;
}

.select {
  line-height: 21px;
  -webkit-appearance: none;
  background-image: url(/images/icons/down-triangle.svg);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  padding: 0px 18px;
  background-size: 11px;
  /* border: 2px solid; for width bug */
}

.textArea {
  height: min-content;
}

.selectWidthAuto {
  padding: 2px 25px 0px 20px;
}

.selectWide {
  background-size: 15px;
}

.selectPlaceholder {
  color: var(--Grey-5, #6b7075);
}

.checked,
.unchecked {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  border: 1px solid #0c171a;
  background: #ffffff;
  border-radius: 5px;
}

.checked {
  background: #0c171a;
}

.checkMarkContainer {
  cursor: pointer;
  user-select: none;
}

.checkMarkIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.checkMarkIcon > path {
  fill: white;
}

.checkMarkIconContainer {
  width: 50%;
  height: 100%;
  position: relative;
  margin: 0 auto;
}
.phoneNumber {
  width: 100%;
}

/* Radio Input Styling */
.radioInput {
  margin-bottom: 0px;
  margin-right: 10px;
  height: 28px;
  width: 28px;
  border: 2px solid black !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.radioInput:hover div {
  height: 18px;
  width: 18px;
  background-color: #222222;
  border-radius: 100%;
  transition: all 0.1s ease-in-out;
}

.radioInput.selected div {
  height: 18px;
  width: 18px;
  background-color: #222222;
  border-radius: 100%;
  transition: all 0.3s ease-in-out;
}

.radioInput input {
  opacity: 0;
  height: 0;
  width: 0;
}

.labelTitle {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
}

.subLabelTitle {
  font-size: 15px;
  line-height: 140%;
}

.percentage {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 16px !important;
  margin-bottom: initial;

  width: 210px;
  height: 50px !important;
  background-color: #f0f0f0 !important;
  border: 1px solid #ebdc4b !important;
  border-radius: 5px !important;
  color: black;
}

/* Mobile CSS Styling */
@media (max-width: 600px) {
  .radioInput {
    margin-bottom: 0px;
    margin-right: 10px;
    height: 28px;
    width: 28px;
    border: 2px solid black !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .radioInput:hover div {
    height: 18px;
    width: 18px;
    background-color: #222222;
    border-radius: 100%;
    transition: all 0.1s ease-in-out;
  }

  .radioInput.selected div {
    height: 18px;
    width: 18px;
    background-color: #222222;
    border-radius: 100%;
    transition: all 0.3s ease-in-out;
  }

  .radioInput input {
    opacity: 0;
    height: 0;
    width: 0;
  }

  .labelTitle {
    font-size: 16px;
    line-height: 21px;
  }
  .subLabelTitle {
    font-size: 14px;
    line-height: 140%;
  }

  .percentage {
    max-width: 70%;
  }
}
