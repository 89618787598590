.container {
  display: flex;
  width: fit-content;
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--grey-2, #cfd3d6);
  border-right: 0px;
  padding: 5px 32px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.option:first-of-type {
  border-radius: 4px 0px 0px 4px;
}

.option:last-of-type {
  border-radius: 0px 4px 4px 0px;
  border-right: 1px solid var(--grey-2, #cfd3d6);
}

.option:hover {
  cursor: pointer;
}

.checked {
  border: 1.5px solid var(--success-green-6, #8aa813) !important;
  padding: 4.5px 31.5px;
  background: var(--success-green-0, #f5fdd6);
}

.checked + .option {
  border-left: 0px !important;
}
