.container {
  background-color: #6a6a6a;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
}

.containerRainbow {
  background: rgba(255, 255, 255, 0.2);
}

.containerDark {
  background: #e7d7c3;
  border-radius: 30px;
}

.bar {
  height: 10px;
  border-radius: 10px;
  background: white;
  transition: width var(--transform-time) ease-in-out;
  display: flex;
  align-items: center;
}

.insideText {
  font-weight: bold;
  font-size: 16px;
  padding-left: 30px;
  color: #ffffff;
}

.outsideTextAlt,
.outsideText {
  font-weight: bold;
  font-size: 16px;
  padding-left: 30px;
}

.outsideTextAlt {
  padding-left: 20px;
}

.active {
  background: linear-gradient(to right, #010101 7.45%, #7bb150 49.29%, #eac02b 100.85%);
}

.activeFull {
  background: linear-gradient(90deg, #d2ed64 4.52%, #ebdc4b 53.07%, #f5c891 97.25%);
}

.rainbow {
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
  background: linear-gradient(104.47deg, #62f2d0 8.39%, #d2ed64 52.98%, #eddd4d 99.48%);
}

.dark {
  background: #000000;
  border-radius: 30px;
}

.gradientOnboarding {
  background: linear-gradient(101.98deg, #d2ed64 17.06%, #f9dc7b 59.17%, #f5c891 99.55%);
}

.greenOnboarding {
  background: var(--candor-neon-green);
}

.greenDashboard {
  background: #a6c917;
  border-radius: 10px;
}

.containerGreenDashboard {
  background: #f8f9f9;
}

.disabled {
  background: #aeaeae;
}
