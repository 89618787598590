.footer {
  font-size: 14px;
  background: var(--candor-black);
  color: white;
}

.footer a,
.footer :global(.link) {
  border: none;
  margin-left: -2px;
  text-decoration: none;
  color: white;
}

.footer a:hover,
.footer :global(.link):hover {
  color: var(--candor-neon-green);
}

.footer :global .widecontainer {
  padding-top: 15px;
}

.footerBody {
  display: flex;
  padding-top: 25px;
  padding-bottom: 20px;
}

.footerBody h4 {
  font-size: 20px;
}

.footerBodyArticles {
  flex: 1 1 auto;
}

.footerBodyArticles > div {
  margin-top: 16px;
}

.footerBodyLinks {
  flex: 0 0 210px;
  text-align: left;
  padding-right: 20px;
}

.footerBodyLinks > h4 {
  margin-bottom: 14px;
}

.footerBodyLinks > div {
  display: block;
  margin: 8px 0;
}

.footerBodyLinks .tag {
  display: inline-block;
  font-size: 9px;
  background-color: white;
  border-radius: 20px;
  padding: 2px 9px;
  text-transform: uppercase;
  margin-top: -2px;
  margin-left: 8px;
  color: #0c353b;
  vertical-align: middle;
  user-select: none;
}

.footerCard {
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 25px;
  cursor: pointer;
}

.footerCard:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.footerCard > div:first-child {
  flex: 0 0 70%;
  padding-right: 25px;
}

.footerCard > div:last-child {
  flex: 0 0 140px;
  width: 140px;
  height: auto;
  display: block;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.footerCard h3 {
  margin: 0;
}

.footerEmail {
  display: flex;
  padding: 40px 40px;
  /* background: linear-gradient(141.24deg, #04171a 6.36%, #08282d 95.2%); */
  border: 2px solid rgba(255, 255, 255, 0.2);
  color: white;
  align-items: center;
}

.footerEmailText {
  flex: 0 0 60%;
  font-size: 16px;
  padding-right: 20px;
  font-size: 22px;
  font-weight: bold;
}

.footerEmail > form {
  flex: 0 0 40%;
}

.communityContainer {
  margin: auto;
  width: 1110px;
}

/* Mobile */
@media (max-width: 600px) {
  .footerBody {
    display: block;
  }

  .footerBodyLinks {
    width: 100%;
    padding: 10px 0 !important;
  }

  .footerBodyArticles {
    padding-top: 15px;
  }

  .footerCard {
    display: block;
  }

  .footerCard > div:first-child {
    padding-right: 0;
  }

  .footerCard > div:last-child {
    display: none !important;
  }

  .footerEmail {
    display: block;
  }

  .footerEmail > form {
    margin-top: 20px;
  }
}
