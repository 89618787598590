.loaderWrapper {
  animation: shimmer 2.8s infinite linear;
  background: linear-gradient(
    to right,
    rgba(226, 226, 226, 0.7) 4%,
    rgba(65, 70, 76, 0.2) 25%,
    rgba(226, 226, 226, 0.7) 36%
  );
  /* background-size: 1000px 100%; */
  background-size: 150% 100%;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0);
  border: none !important;

  display: flex;
  transition: all 0.2s ease;
}
.flex {
  flex: 1;
}

/* so it would have height if empty */
.loaderWrapper:after {
  content: ".";
  opacity: 0;
}

.loaderWrapper > * {
  visibility: hidden;
}

@keyframes shimmer {
  0% {
    /* background-position: -1000px 0; */
    background-position: 150% 0;
  }
  100% {
    /* background-position: 1000px 0; */
    background-position: -150% 0;
  }
}

.solid {
  animation: shimmer 10s infinite linear;
  background: linear-gradient(to right, rgba(226, 226, 226, 0.7) 4%, rgba(226, 226, 226, 0.7) 36%);
}
