.articleList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  column-gap: 20px;
  row-gap: 40px;
  justify-content: space-between;
  margin: 40px 0;
}

.articleCard {
  cursor: pointer;
  overflow: auto;
  position: relative;
  transition: transform 0.25s ease-in-out;
  padding-bottom: 37px;
  opacity: 0;
}

.articleCard:hover {
  transform: translateY(-7px);
}

.articleCard:hover .articleCardLink {
  opacity: 1;
}

.articleCardImage {
  width: 100%;
  height: 100px;
  background-size: cover !important;
  background-position: center !important;
}

:global(.dark) .articleCardImage {
  filter: grayscale(1);
}

:global(.dark) .articleCard:hover .articleCardImage {
  filter: none;
}

.articleCardTitle {
  margin-top: 15px;
}

.articleCardTitle > h3 {
  font-family: "GT Super", serif;
  display: -webkit-box;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  height: 56px;
  margin: 8px 12px;
  overflow: hidden;
  /* Non-standard CSS for adding ellipsis */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.articleCardTitle > h3 > a {
  border-bottom: none;
  box-shadow: none;
}

.articleCardTitle > h3 > svg {
  height: 15px;
  width: 15px;
  vertical-align: middle;
  fill: #fff;
}

.articleCardLink {
  color: #191d18;
  opacity: 0.4;
  position: absolute;
  left: 12px;
  bottom: 12px;
  margin: 0;
  padding-top: 12px;
  font-size: 14px;
  transition: opacity 0.25s ease;
  text-decoration: none;
}

.articleCardLink:hover {
  box-shadow: none;
}

.disclaimer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  margin-top: 5px;
}

.disclaimer > p {
  color: #191d18;
  opacity: 0.4;
  font-weight: bold;
  margin-left: 8px;
  align-items: center;
  margin-bottom: 0;
  text-transform: uppercase;
}

.disclaimer > .chicklet {
  position: relative;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.85);
  background-color: #f4c88f;
  border-radius: 50%;
  vertical-align: middle;
  margin-left: 6px;
  margin-top: 6px;
  width: fit-content;
  padding: 4px 10px;
}

.disclaimer > .chicklet > svg {
  height: 15px;
  width: 15px;
  vertical-align: middle;
  fill: #000;
}

.overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  color: white;
  padding: 20px;
  z-index: 3;
  cursor: pointer;
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7));
}

.overlay > button {
  margin-top: 6px;
  font-weight: bold;
}

.overlay > p {
  font-style: italic;
  font-weight: medium;
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

.locked:hover .overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.locked > h3 {
  color: rgb(219, 219, 219);
}

/* Mobile */
@media (max-width: 600px) {
  .articleList {
    display: block;
  }

  .articleCard {
    width: 100%;
    margin-bottom: 20px;
  }
  .chicklet {
    margin-top: 20px;
  }
}
