.filterChip {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 2px 4px;
  min-width: 80px;
  border-radius: 20px;
  border: 1px solid rgba(128, 128, 128, 1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: rgba(128, 128, 128, 1);
  padding: 4px 14px;
  cursor: pointer;
}

.filterChip:hover {
  background-color: rgba(231, 215, 195, 1);
  color: #000000;
  border: 1px solid #e7d7c3;
}

.filterChip:nth-of-type(1) {
  margin-left: 0;
}

.activeChip {
  background-color: black;
  color: rgba(255, 255, 255, 1);
  border-color: black;
}
