.button {
  justify-content: center;
  align-items: center;
  display: inline-flex;
  font-size: 16px;
  font-weight: 500;
  color: var(--Primary-Grey-9, #141719);
  height: fit-content;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  transition-property: none;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;
  border: unset;
}

.button:hover {
  color: var(--Primary-Grey-9, #141719);
}

.button:disabled {
  cursor: not-allowed;
  border: 1px solid var(--Grey-1, #e6e8ea);
  background: var(--Grey-0, #f8f9f9) !important;
  color: var(--Grey-3, #a7abb0) !important;
}

.button.loading {
  cursor: not-allowed;
}

.button > svg {
  height: 16px;
  width: 16px;
}

.button:disabled > span > svg > path {
  fill: #a7abb0;
}

.primary {
  background: var(--Primary-Green-3, #d2ed64);
}

.primary:not(.loading):hover {
  background: var(--Success-Green-1, #ebfaac);
}

.secondary {
  background: var(--Grey-1, #e6e8ea);
}

.secondary:not(.loading):hover {
  background: var(--Grey-2, #cfd3d6);
}

.outline {
  background: var(--Primary-White, #fff);
  border: 1px solid var(--Grey-2, #cfd3d6);
}

.outline:not(.loading):hover {
  background: var(--Grey-0, #f8f9f9);
}

.text {
  background: transparent;
}

.text:not(.loading):hover {
  background: var(--Grey-0, #f8f9f9);
  background: var(--Grey-0, #f8f9f9);
  border-radius: 4px;
}

.plain {
  background: none;
  border: none;
  color: #6f860f;
}

.plain > span > svg > path {
  fill: #6f860f;
}

.plain:not(.loading):hover {
  text-decoration: underline;
}

.plain:disabled {
  background: none !important;
  border: none;
  text-decoration: none;
}

.monochrome {
  background: none;
  border: none;
  color: var(--Grey-5, #6b7075);
}

.monochrome > span > svg > path {
  fill: #6b7075;
}

.monochrome:not(.loading):hover {
  text-decoration: underline;
}

.monochrome:disabled {
  background: none !important;
  border: none;
  text-decoration: none;
}

.marketing {
  background: #d35e27;
  color: #fff;
}

.marketing:not(.loading):hover {
  background: #ffe6cf;
  color: var(--Primary-Grey-9, #141719);
}

.dangerPrimary {
  background: #c83232;
  color: #fff;
}

.dangerPrimary:not(.loading):hover {
  background: #a72529;
  color: #fff;
}

.dangerOutline {
  border: 1px solid var(--Critical-Red-1, #f3d2ce);
  border-radius: var(--Radius-S, 4px);
  color: var(--Critical-Red-5, #c84432);
  background: var(--Primary-White, #fff);
}

.dangerOutline:not(.loading):hover {
  border: 1px solid var(--Critical-Red-5, #c84432);
  background: #faeeec;
  color: var(--Critical-Red-5, #c84432);
}

.dangerOutline:disabled {
  border: 1px solid var(--Grey-1, #e6e8ea);
}

.square {
  border-radius: var(--Radius-S, 4px);
}

.round {
  border-radius: 50px;
}

.slim {
  padding: 5px 16px;
}

.default {
  padding: 9px 24px;
}

.large {
  padding: 15px 36px;
  font-size: 18px;
}

.slimIcon {
  padding: 8px;
}

.defaultIcon {
  padding: 10px;
}

.largeIcon {
  padding: 12px;
}

.iconAndText > svg {
  margin-right: 6px;
}

.disabledText:disabled {
  background: none !important;
  border: none;
}

.upperCase {
  text-transform: uppercase;
  font-weight: bold;
}

.loadingContainer {
  height: 20px;
  width: 20px;
  margin-right: 6px;
}

.loader,
.loader:after {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.loader {
  font-size: 4px;
  text-indent: -9999em;
  border-top: 0.6em solid #141719;
  border-right: 0.6em solid #141719;
  border-bottom: 0.6em solid #141719;
  border-left: 0.6em solid transparent;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.dangerPrimary > .loadingContainer > .loader {
  border-top: 0.6em solid #fff;
  border-right: 0.6em solid #fff;
  border-bottom: 0.6em solid #fff;
}

.dangerOutline > .loadingContainer > .loader {
  border-top: 0.6em solid #c84432;
  border-right: 0.6em solid #c84432;
  border-bottom: 0.6em solid #c84432;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
