.container {
  border-radius: var(--p-space-6, 24px);
  background: var(--icon-color);
  display: flex;
  padding: var(--Radius-XS, 2px) 8px;
  align-items: center;
  gap: 4px;
  width: fit-content;
  color: var(--Primary-Grey-9, #141719);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.neutral {
  --icon-color: var(--Grey-1, #e6e8ea);
  --dot-text-color: #1c1f23;
  background: var(--Grey-1, #e6e8ea);
}

.neutralGold {
  --icon-color: #a37d5d;
  --dot-text-color: #a37d5d;
  background: var(--Tertiary-Gold-3, #f9e2bc);
}

.success {
  --icon-color: #889e40;
  --dot-text-color: #657721;
  background: var(--Success-Green-1, #ebfaac);
}

.attention {
  --icon-color: #f0b114;
  --dot-text-color: #c88a0f;
  background: #feeaab;
}

.warning {
  --icon-color: #e78138;
  --dot-text-color: #c36229;
  background: var(--Warning-Orange-2, #f5d1ab);
}

.critical {
  --icon-color: #c83232;
  --dot-text-color: #a72529;
  background: var(--Critical-Red-1, #f3d2ce);
}

.icon {
  display: flex;
  width: 12px;
  height: 12px;
}

.icon > svg > * {
  fill: var(--Primary-Grey-9, #141719);
}

.light > .icon > svg > * {
  fill: var(--icon-color);
}

.dot > .icon {
  display: none;
}

.square {
  border-radius: 4px;
}

.light {
  background: none;
}

.dot {
  background: none;
  color: var(--dot-text-color);
}

.dotWrapper {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--icon-color);
}
