.starIcon {
  width: 24px;
  margin-right: 12px;
  margin-left: 8px;
}

.starIcon path {
  fill: #ffc107;
}

.starIconSec16 path {
  fill: var(--alert-red);
}
