.container {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 12px;
}

.actionable {
  cursor: pointer;
}

.actionable:hover {
  color: #999999;
  border: 1px solid #999999;
}

.actionable:active {
  color: #0f0f0f;
  border: 1px solid #0f0f0f;
}

.active {
  color: #ffffff;
  background: #0f0f0f;
  border: 1px solid #0f0f0f;
  border-radius: 100px;
}

.active:hover {
  background-color: #262626;
  color: #f2f2f2;
  border: 1px solid #404040;
}

.green {
  background: var(--tertiary-green-1, #ebfaac);
  border: 1px solid var(--tertiary-green-1, #ebfaac);
}

.grey {
  background: var(--candor-grey-30, #e6e8ea);
}
